// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { Country, State } from "country-state-city";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
import "yup-phone-lite";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Color } from "@material-ui/lab/Alert";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export interface CountryCodeObj {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}

export interface SelectEventType {
    target: {
        value: string | unknown;
    }
}

interface CountryInterfaceData {
    name: string;
    ICountry: [];
    isoCode: string;
    flag: string;
    phonecode: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: {
        zoneName: string;
        gmtOffset: string;
        gmtOffsetName: string;
        abbreviation: string;
        tzName: string;
    };
}
interface FilteredData {
    name: string;
}

export interface RowDataInterface {
    id: number; lastName: string; firstName: string; age: number;
}

interface FileData {
    file: File;
    fileUrl: string;
}
export interface StaffMember {
    id: string;
    type: string;
    attributes: {
        id: number;
        firstname?: string;
        lastname?: string;
        email?: string;
        phone_number?: string;
        status?: string;
        name?: string;
        file_type?: string | null;
        document_category?: string;
        account_id?: number;
        created_at?: string;
        updated_at?: string;
        url?: URL;
    };
};

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
};

interface ValidResponseType {
    personal_id: StaffMember[];
    business_registration_certificate: StaffMember[];
    agent_docs: StaffMember[];
    message: string;
    data: StaffMember[];
    meta: {
        message: string;
    };
    countryCode: string
};

export interface BankInformation {
    attributes: {
        id: string;
        bankholder_name: string;
        account_number: string;
        bank_name: string;
        bank_address: string;
        iban: string;
        swiftcode: string;
        primary: boolean;
        created_invoices: null | string;
        paid_invoices: null | string;
    }
};

interface BankInformationResponse {
    data: Array<BankInformation>;
    meta: {
        message: string;
    };
};

export interface UserDetails {
    attributes: {
        allow_navigate: boolean;
        first_name: string;
        company_name: string;
        email: string;
        no_of_employees: string;
        full_phone_number: string;
        country: string;
        state: string;
        city: string;
        verification_type: string;
        activated: string;
        is_logged_in: boolean;
        gender: string;
        date_of_birth: string;
        marital_status: string;
        created_at: string;
        updated_at: string;
        role: string;
        phone_number: string,
        marriage_anniversary: string,
        allow_all: boolean,
        preferred_notification: {
            bulkUpdates: boolean,
            conversationMessage: boolean,
            newApplicationRequirement: boolean,
            applicationStage: boolean,
        }
        image: {
            url: string;
        };
        log: {
            data: [
                {
                    id: string;
                    type: string;
                    attributes: {
                        action: string;
                        description: string;
                        ip_address: string;
                        user_id: number;
                        date: string;
                    }
                }
            ]
        }
    }
};

export interface GetAllDocumentResponse {
    personal_id: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    business_registration_certificate: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    agent_docs: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    meta: {
        message: "List of all documents"
    }
}

interface UserDetailsResponse {
    data: UserDetails;
    meta: {
        message: string;
    };
};

interface InvalidResponseType {
    errors: string;
};

interface InvalidResponseErrorType {
    errors: [
        {
            doc: string;
        }
    ]
};

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface ValidResponseType {
    message: string;
    data: StaffMember[];
    meta: {
        message: string;
    }
};

interface DocumentUrl {
    url: string;
}

interface DocumentAttributes {
    id: number;
    name: string;
    file_type: string | null;
    document_category: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    url: DocumentUrl
    attributes: DocumentAttributes
}

interface Document {
    file: string | Blob;
    id: string;
    type: string;
    attributes?: DocumentAttributes;
    fileUrl: string;
}

export interface ActivityTable {
    "id": string;
    "type": string;
    "attributes": {
        "action": string;
        "description": string;
        "ip_address": string;
        "user_id": number;
        "date": string;
    }
}

interface Category {
    data: Document[];
}


interface Meta {
    message: string;
}
interface FileResponse {
    message: string;
    personal_id: PersonalId;
    business_registration_certificate: BusinessRegistration;
    agent_docs: Category;
    meta: Meta;
}

interface BusinessRegistration {
    data: Document[];
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        file_type: null,
        document_category: string,
        account_id: number,
        created_at: string,
        updated_at: string,
        url: {
            url: string
        }
    }
}
interface PersonalId {
    data: Document[];
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        file_type: null,
        document_category: string,
        account_id: number,
        created_at: string,
        updated_at: string,
        url: {
            url: string
        }
    }
}

interface ManageBranch {
    id: number;
    branch_name: string;
    email: string;
    country_code: number;
    phone_number: number;
    managed_by: string;
    date: string;
    time: string;
    created_at: string;
    updated_at: string;
}

interface ManageBranchData {
    id: string;
    type: string;
    attributes: ManageBranch;
}

interface CountryInfo {
    alpha2: string;
    name: string;
    country_code: string;
  }
  
  interface Account {
    id: number;
    email: string;
    role: string;
  }
  interface BankDetailsAttributes {
    id: number;
    bankholder_name: string;
    account_number: string;
    bank_name: string;
    bank_address: string;
    iban: string;
    swiftcode: string;
    primary: boolean;
    currency_preference: string;
  }

  interface BankDetails {
    id: string;
    type: string;
    attributes: BankDetailsAttributes;
  }
  
  interface BankErrorResponse {
    errors: string[];
  }

  interface CurrencyInfo {
    country: string;
    currency: string;
    code: string;
  }
  
// Customizable Area End

interface S {
    // Customizable Area Start
    imageUrl: string | null;
    selectedFile: string | null | File;
    selectedFileNew: File | null;
    uploadedDocumentData:File | null;
    toggleDrawer: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    userDetails: UserDetails;
    isLoader: boolean;
    selected: string;
    value: number;
    isPrimary: boolean;

    memberTxtValue: string;
    memberError: boolean;
    memberErrorTxt: string;

    memberEmailTxtValue: string;
    memberEmailError: boolean;
    memberEmailErrorTxt: string;

    genderError: false,
    genderValue: string | unknown;
    genderErrorTxt: string;

    maritalValue: string | unknown;
    maritalErrorTxt: string;
    maritalError: boolean;

    countryData: Array<CountryInterfaceData>;
    countryValue: string | unknown;
    countryError: boolean;
    countryErrorTxt: string;

    stateData: Array<FilteredData>;
    stateValue: string | unknown;
    stateError: boolean;
    stateErrorTxt: string;

    cityError: boolean;
    cityValue: string;
    cityErrorTxt: string;

    mobileNumberError: boolean;
    mobileNumberValue: string;
    mobileNumberErrorTxt: string;


    isCalender: boolean;
    birthDateValue: string;
    birthDate: Date | null | string;
    birthdayError: boolean;
    birthdayErrorTxt: string;

    isMarriageCalender: boolean;
    marriageDateValue: string;
    marriageDate: Date | null | string;
    marriageError: boolean;
    marriageErrorTxt: string;

    curentPswrd: string;
    currentPswrdError: boolean;
    currentPswrdErrorTxt: string;

    newPswrd: string;
    newPswrdErrorTxt: string;
    newPswrdError: boolean;

    confirmPswrd: string;
    confrimPswrdErrorTxt: string;
    confrimPswrdError: boolean;

    activityTableData: Array<ActivityTable>;

    allowAll: boolean;
    notifications: {
        [key: string]: boolean;
    };

    isDelete: boolean;
    isModal:boolean;

    accountNameValue:string;
    accountNameError:boolean;
    accountNameErrorTxt: string;

    accountNumberValue: string;
    accountNumberError: boolean;
    accountNumberErrorTxt: string;

    
    accountBankNameValue:string;
    accountBankNameError: boolean;
    accountBankNameErrorTxt: string;

    bankAdrsValue:string;
    bankAdrsError: boolean;
    bankAdrsErrorTxt: string;

    ibanValue:string;
    ibanError: boolean;
    ibanErrorTxt: string;

    shiftValue:string;
    shiftError: boolean;
    shiftErrorTxt: string;
    branchModal: boolean;
    branchName: string;
    branchEmailID: string;
    branchCountryCode: string;
    branchMobileNumber: string;
    branchAgent: string;
    allBranchData:ManageBranchData[],
    allDocumentsData:any,
    countryCodes: CountryInfo[],
    branchNameError: string;
    branchEmailError: string;
    branchMobileNumberError: string;
    branchAgentError: string;
    isBranchEdit: boolean,
    branchEditID:number,
    allBankDetails: BankDetails[],
    bankEditID: number,
    bankEditName: string,
    isBankEdit: boolean,
    bankErrorResponse: BankErrorResponse[],
    currencyCode:CurrencyInfo[],
    selectedCurrency:string,
    currencyError: boolean,
    currencyErrorTxt: string,
    expandedRowId: number | null;
    isBranchDelete:boolean;
    staffRowData: {
        id: number;
        type: string;
        attributes: {
            first_name: string;
            last_name: string;
            email: string;
            phone_number: number;
            country_code: number;
            role: string;
            status: string;
        };
    }[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    
    postProfilePictureAPIcallId = "";
    getProfileAPICallId: string = "";
    changePswrdAPICallId: string = "";
    fetchAllBranchAPICallID: string = ""
    fetchAllDocumentsID: string = "";
    uploadDocumentID :string= "";
    deleteDocumentID:string= "";
    getCountryCodeAPICallID: string = ""
    fetchManagedByAPICallID: string = ""
    saveBranchAPICallID: string = ""
    validateMobileNoAPICallID: string = ""
    fetchAllBanksAPICallID: string = ""
    saveBankInfoAPICallID: string = ""
    saveNotificationPreference: string = ""
    saveProfileAccountAPICallID: string = ""

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
        this.state = {
            imageUrl: "",
            selectedFile: "",
            selectedFileNew:null,
            uploadedDocumentData:null,
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            userDetails: {} as UserDetails,
            isLoader: false,
            toggleDrawer: false,
            selected: "Account",
            value: 0,
            isPrimary: false,

            memberTxtValue: "",
            memberError: false,
            memberErrorTxt: "",

            memberEmailTxtValue: "",
            memberEmailError: false,
            memberEmailErrorTxt: "",

            genderError: false,
            genderValue: "none",
            genderErrorTxt: "",

            maritalValue: "none",
            maritalErrorTxt: "",
            maritalError: false,

            countryData: Country.getAllCountries() as never,
            countryValue: "",
            countryError: false,
            countryErrorTxt: "",

            stateData: [],
            stateValue: "",
            stateError: false,
            stateErrorTxt: "",

            cityError: false,
            cityValue: "",
            cityErrorTxt: "",

            mobileNumberError: false,
            mobileNumberValue: "",
            mobileNumberErrorTxt: "",

            isCalender: false,
            birthDateValue: "Select birthday",
            birthDate: new Date(),
            birthdayError: false,
            birthdayErrorTxt: "",

            isMarriageCalender: false,
            marriageDateValue: "Select marriage anniversary",
            marriageDate: new Date(),
            marriageError: false,
            marriageErrorTxt: "",

            curentPswrd: "",
            currentPswrdError: false,
            currentPswrdErrorTxt: "",

            newPswrd: "",
            newPswrdErrorTxt: "",
            newPswrdError: false,

            confirmPswrd: "",
            confrimPswrdErrorTxt: "",
            confrimPswrdError: false,

            activityTableData: [],

            allowAll: false,
            notifications: {
                applicationStage: false,
                bulkUpdates: false,
                conversationMessage: false,
                newApplicationRequirement: false,
            },

            isDelete:false,
            isModal:false,
            
            accountNameValue:"",
    accountNameError:false,
    accountNameErrorTxt: "",

    accountNumberValue: "",
    accountNumberError: false,
    accountNumberErrorTxt: "",

    
    accountBankNameValue:"",
    accountBankNameError: false,
    accountBankNameErrorTxt: "",

    bankAdrsValue:"",
    bankAdrsError: false,
    bankAdrsErrorTxt: "",

    ibanValue:"",
    ibanError: false,
    ibanErrorTxt: "",

    shiftValue:"",
    shiftError: false,
    shiftErrorTxt: "",
    branchModal: false,
    branchName:"",
    branchEmailID:"",
    branchCountryCode:"91-IN",
    branchMobileNumber:"",
    branchAgent:"",
    allBranchData:[],
    allDocumentsData:[],
    countryCodes:[],
    branchNameError:"",
    branchEmailError:"",
    branchMobileNumberError:"",
    branchAgentError:"",
    isBranchEdit: false,
    branchEditID:0,
    allBankDetails:[],
    bankEditID:0,
    bankEditName:"",
    isBankEdit:false,
    bankErrorResponse:[],
    isBranchDelete: false,
    currencyCode:[
        { country: "Australia", currency: "Australian Dollar", code: "AUD" },
        { country: "Brazil", currency: "Brazilian Real", code: "BRL" },
        { country: "Canada", currency: "Canadian Dollar", code: "CAD" },
        { country: "China", currency: "Chinese Yuan", code: "CNY" },
        { country: "Denmark", currency: "Danish Krone", code: "DKK" },
        { country: "Eurozone", currency: "Euro", code: "EUR" },
        { country: "India", currency: "Indian Rupee", code: "INR" },
        { country: "Japan", currency: "Japanese Yen", code: "JPY" },
        { country: "Mexico", currency: "Mexican Peso", code: "MXN" },
        { country: "New Zealand", currency: "New Zealand Dollar", code: "NZD" },
        { country: "Norway", currency: "Norwegian Krone", code: "NOK" },
        { country: "Russia", currency: "Russian Ruble", code: "RUB" },
        { country: "Singapore", currency: "Singapore Dollar", code: "SGD" },
        { country: "South Africa", currency: "South African Rand", code: "ZAR" },
        { country: "South Korea", currency: "South Korean Won", code: "KRW" },
        { country: "Sweden", currency: "Swedish Krona", code: "SEK" },
        { country: "Switzerland", currency: "Swiss Franc", code: "CHF" },
        { country: "United Kingdom", currency: "British Pound", code: "GBP" },
        { country: "United States", currency: "US Dollar", code: "USD" }
      ],
      selectedCurrency:"none",
      currencyError: false,
      currencyErrorTxt: "",
      expandedRowId: null,
      staffRowData:configJSON.dummyManageStaffData
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        await this.getProfileAccount();
        await this.getCountryCode();
        await this.fetchAllBranches()
        await this.fetchAllDocuments();
        await this.fetchAllBankDetails()
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    selectedFile: file,
                    imageUrl: reader.result as string
                }, () => {
                    this.postProfilePicture(file)
                });
            };
            reader.readAsDataURL(file);
        }
    };

    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            pageName
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggleAbove = (option: string) => {
        this.setState({ selected: option });
    };

    apiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiData;
        let token = await getStorageData("token")
        const header = {
            "Content-Type": contentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data) || responseJson.message || responseJson.agent_docs || responseJson.business_registration_certificate || responseJson.personal_id || responseJson.meta || Array.isArray(responseJson);
    };

    apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType & UserDetailsResponse & FileResponse) => {
        if (apiRequestCallId === this.getProfileAPICallId) {
            this.getProfileSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.changePswrdAPICallId) {
            this.changePswrdeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postProfilePictureAPIcallId) {
            this.profilePictureSuccessCall(responseJson);
        }
        if (apiRequestCallId === this.fetchAllBranchAPICallID) {
            this.fetchAllBranchSuccessCall(responseJson)
        }
         /* istanbul ignore next */
        if (apiRequestCallId === this.fetchAllDocumentsID) {
            this.fetchAllDocumentsSuccessCall(responseJson)
        } /* istanbul ignore next */
        if (apiRequestCallId === this.uploadDocumentID) { 
            this.uploadDocumentSuccessCall(responseJson); 
        } /* istanbul ignore next */
        if (apiRequestCallId === this.deleteDocumentID) { 
            this.deleteDocumentSuccessCall(); 
        }
        if (apiRequestCallId === this.getCountryCodeAPICallID) {
            this.setCountryCodes(responseJson)
        }
        if (apiRequestCallId === this.saveBranchAPICallID) {
            this.resetBranchFormData()
        }
        if (apiRequestCallId === this.fetchAllBanksAPICallID) {
            this.fetchAllBankSuccessCall(responseJson)
        }
        if (apiRequestCallId === this.saveBankInfoAPICallID) {
            this.setBankMessages(responseJson)
        }
        if(apiRequestCallId === this.saveProfileAccountAPICallID) {
            this.handleUpdateProfile(responseJson)
        }
    };

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponseErrorType) => {
        if (apiRequestCallId === this.postProfilePictureAPIcallId) {
            this.profilePictureFailureCall(responseJson);
        }
        if (apiRequestCallId === this.changePswrdAPICallId) {
            this.changePswrdFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.validateMobileNoAPICallID) {
            this.setContactErrors(responseJson)
        }
        if (apiRequestCallId === this.saveBankInfoAPICallID) {
            this.setBankResponseError(responseJson)
        }
    };

    changePswrdeSucessCallBack = (responseJson: ValidResponseType) => {
        this.setState({
            isLoader: false,
            isAlert: true,
            alertMsg: responseJson.message,
            alertType: "success",
            curentPswrd: "",
            newPswrd: "",
            confirmPswrd: ""
        });
    };

    changePswrdFailureCallBack = (responseJson: InvalidResponseType) => {
        this.setState({
            isAlert: true,
            alertMsg: responseJson.errors,
            alertType: "error",
            isLoader: false
        });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId, responseJson);
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson);
            }
        }
    };

    getProfileAccount = async () => {
        this.setState({ isLoader: true });
        let userDetails = await getStorageData("userDetails")
        this.getProfileAPICallId = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getProfileEndPoint + userDetails
        });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    getProfileSuccessCallBack = (responseJson: UserDetailsResponse) => {
        const responseData = responseJson.data.attributes
        this.setState({
            userDetails: responseJson.data,
            memberTxtValue: responseData.first_name,
            memberEmailTxtValue: responseData.email,
            genderValue: responseData.gender === null ? configJSON.genderValue : responseData?.gender,
            birthDateValue: responseData.date_of_birth === null ? configJSON.birthdayValue : responseData?.date_of_birth,
            maritalValue: responseData.marital_status === null ? configJSON.genderValue : responseData?.marital_status,
            countryValue: responseData.country,
            stateValue: responseData.state,
            cityValue: responseData.city,
            isLoader: false,
            activityTableData: responseJson?.data?.attributes.log.data,
            mobileNumberValue:responseData.phone_number,
            marriageDateValue: responseData.marriage_anniversary === null ? "Select marriage anniversary" : responseData.marriage_anniversary,
            allowAll:responseData.allow_all,
            notifications: {
                bulkUpdates: responseData?.preferred_notification?.bulkUpdates,
                conversationMessage: responseData?.preferred_notification?.conversationMessage,
                newApplicationRequirement: responseData?.preferred_notification?.newApplicationRequirement,
                applicationStage: responseData?.preferred_notification?.applicationStage
            }
        }, () => {
            this.handleCountryChange({ target: { value: responseData.country } })
        }
        );
    };

    postProfilePicture = async (file: File) => {
        this.setState({ isLoader: true });
        const formdata = new FormData();
        formdata.append("image", this.state.selectedFile as File);
        this.postProfilePictureAPIcallId = await this.apiCall({
            method: configJSON.httpPostMethod,
            endPoint: configJSON.postProfileEndPoint,
            type: configJSON.bankdetailsType,
            body: formdata
        });
    };

    profilePictureSuccessCall = (responseJson: FileResponse) => {
        this.setState(
            {
                alertMsg: responseJson.meta.message,
                isAlert: true,
                alertType: "success",
                isLoader: false
            },
            () => { this.getProfileAccount() }
        )
    };

    profilePictureFailureCall = (responseJson: InvalidResponseType) => {
        this.setState({
            isAlert: true,
            alertMsg: responseJson.errors,
            alertType: "error",
            isLoader: false
        });
    };

    memberTxtChange = (event: { target: { value: string; }; }) => {
        const regex = configJSON.regaxText;
        const inputValue = event.target.value;
        if (regex.test(inputValue) || inputValue === "") {
            this.setState({ memberTxtValue: inputValue });
        }
    };

    memberEmailChange = (event: { target: { value: string; }; }) => {
        this.setState({ memberEmailTxtValue: event.target.value });
    };

    genderChange = (event: SelectEventType) => {
        this.setState({ genderValue: event.target.value });
    };

    maritalChange = (event: SelectEventType) => {
        this.setState({ maritalValue: event.target.value });
    };

    handleCountryChange = (event: SelectEventType) => {
        const statedata = State.getAllStates()
        this.setState({ countryValue: event.target.value }, () => {
            const filteredData = statedata.filter((data: { countryCode: string; }) => data.countryCode === event.target.value);
            if (filteredData.length === 0) {
                this.setState({
                    stateData: [{
                        name: "N/A"
                    }],

                })
            }
            else this.setState({
                stateData: filteredData,
            })
        }
        )
    };

    handleStateChange = (event: SelectEventType) => {
        this.setState({ stateValue: event.target.value });
    };

    memberCityChange = (event: { target: { value: string; }; }) => {
        const regex = configJSON.regaxText
        const inputValue = event.target.value;
        if (regex.test(inputValue) || inputValue === "") {
            this.setState({ cityValue: inputValue });
        }
    };

    mobileChange = (event: { target: { value: string; }; }) => {
        const regex = configJSON.mobileReagx
        const inputValue = event.target.value.replace(/\D/g, "").trim();
        if (regex.test(inputValue) || inputValue === "") {
            this.setState({ mobileNumberValue: inputValue });
        }
    };
    onCalenderClick = () => {
        this.setState({ isCalender: !this.state.isCalender });
    };

    handleDateChange = (date: Date | null) => {
        if (date) {
            const dayValue = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${dayValue}/${month}/${year}`;

            this.setState({
                birthDate: date,
                isCalender: !this.state.isCalender,
                birthDateValue: formattedDate
            });
        } else {
            this.setState({
                birthDate: null,
                isCalender: !this.state.isCalender,
                birthDateValue: ""
            });
        }
    };

    onMarrigeCalendarClick = () => {
        this.setState({ isMarriageCalender: !this.state.isMarriageCalender });
    };

    handleMarrigeDateChange = (date: Date | null) => {
        if (date) {
            const dayValue = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${dayValue}-${month}-${year}`;

            this.setState({
                marriageDate: date,
                isMarriageCalender: !this.state.isMarriageCalender,
                marriageDateValue: formattedDate
            });
        } else {
            this.setState({
                marriageDate: null,
                isMarriageCalender: !this.state.isMarriageCalender,
                marriageDateValue: ""
            });
        }
    };


    handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const emailRegex = configJSON.emailRegex
        const validationChecks = [
            { value: this.state.memberTxtValue === "" || this.state.memberTxtValue === null, errorState: "memberError", errorTxtState: "memberErrorTxt", errorMessage: "Please enter the member's name." },
            { value: this.state.genderValue === "none", errorState: "genderError", errorTxtState: "genderErrorTxt", errorMessage: "Please select the member's gender." },
            { value: this.state.maritalValue === "none", errorState: "maritalError", errorTxtState: "maritalErrorTxt", errorMessage: "Please select the member's marital status." },
            { value: this.state.countryValue === "none", errorState: "countryError", errorTxtState: "countryErrorTxt", errorMessage: "Please select the member's country." },
            { value: this.state.stateValue === "none", errorState: "stateError", errorTxtState: "stateErrorTxt", errorMessage: "Please select the member's state." },
            { value: this.state.mobileNumberValue === "" || this.state.mobileNumberValue === null, errorState: "mobileNumberError", errorTxtState: "mobileNumberErrorTxt", errorMessage: "Please enter the member's mobile number." },
            { value: this.state.cityValue === "", errorState: "cityError", errorTxtState: "cityErrorTxt", errorMessage: "Please enter the member's city." },
            { value: this.state.birthDateValue === "Select birthday", errorState: "birthdayError", errorTxtState: "birthdayErrorTxt", errorMessage: "Please select the member's birthday." },
            {
                value: this.state.memberEmailTxtValue === "" || !emailRegex.test(this.state.memberEmailTxtValue),
                errorState: "memberEmailError",
                errorTxtState: "memberEmailErrorTxt",
                errorMessage: this.state.memberEmailTxtValue === "" ? "Please enter the member's email address." : "Please enter a valid email address."
            }
        ];

        this.setState(prevState => {
            const updatedState: { [key: string]: boolean | string } = {};
            validationChecks.forEach(({ value, errorState, errorTxtState, errorMessage }) => {
                updatedState[errorState] = value;
                updatedState[errorTxtState] = value ? errorMessage : "";
            });
            return { ...prevState, ...updatedState };
        })
        
        let trueCount = 0;

        for (const item of validationChecks) {
            if (item.value) {
                trueCount++;
                break;
            }
        }

        if (trueCount === 0) {
            this.saveProfileAccount();
        }
    };

    curentPswrdChange = (event: { target: { value: string; }; }) => {
        this.setState({ curentPswrd: event.target.value });
    };

    newPswrdChange = (event: { target: { value: string; }; }) => {
        this.setState({ newPswrd: event.target.value });
    };

    confirmPswrdChange = (event: { target: { value: string; }; }) => {
        this.setState({ confirmPswrd: event.target.value });
    };

    onPswrdSave = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        let isCheck = true;
        const passwordRegex = configJSON.passwordRegex
        if (this.state.curentPswrd.length === 0) {
            isCheck = false;
            this.setState({ currentPswrdError: true, currentPswrdErrorTxt: configJSON.currentPswrdReq });
        } else if (!passwordRegex.test(this.state.curentPswrd)) {
            isCheck = false;
            this.setState({ currentPswrdError: true, currentPswrdErrorTxt: configJSON.currentPswrdValid });
        }
        else {
            this.setState({ currentPswrdError: false, currentPswrdErrorTxt: "" });
        }

        if (this.state.newPswrd.length === 0) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdReq });
        } else if (!passwordRegex.test(this.state.newPswrd)) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdValid });
        } else if (this.state.curentPswrd === this.state.newPswrd) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdNotSame });
        }
        else {
            this.setState({ newPswrdError: false, newPswrdErrorTxt: "" });
        }

        if (this.state.confirmPswrd.length === 0) {
            isCheck = false;
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdReq });
        } else if (!passwordRegex.test(this.state.confirmPswrd)) {
            isCheck = false;
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdValid });
        } else if (this.state.newPswrd !== this.state.confirmPswrd) {
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdNotSame });
        }
        else {
            this.setState({ confrimPswrdError: false, confrimPswrdErrorTxt: "" });
        }
        if (isCheck) {
            this.changePasswordApi()
        }
    };

    changePasswordApi = async () => {
        this.setState({ isLoader: true })
        const body = {
            current_password: this.state.curentPswrd,
            new_password: this.state.newPswrd,
            confirm_password: this.state.confirmPswrd
        }
        this.changePswrdAPICallId = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.changePswrdApimethod,
            endPoint: configJSON.changePswrdEndPoint,
            body: body
        });
    };

    handleToggleNotifications = (name: string) => () => {
        this.setState((prevState) => {
            const newNotifications = {
                ...prevState.notifications,
                [name]: !prevState.notifications[name],
            };
            const allowAll = Object.values(newNotifications).every(Boolean);

            return {
                notifications: newNotifications,
                allowAll,
            };
        }, this.updateNotificationPreference);
    };

    handleAllowAllToggle = () => {
        const allowAll = !this.state.allowAll;
        this.setState({
            allowAll,
            notifications: Object.keys(this.state.notifications).reduce((accValue, keyValue) => {
                accValue[keyValue] = allowAll;
                return accValue;
            }, {} as { [keyValue: string]: boolean }),
        }, this.updateNotificationPreference);
        
    };

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };

    handleDelete = () => {
        this.setState({ isDelete: !this.state.isDelete });
    };

    handlBrancheDelete = () => {
        this.setState({ isBranchDelete: !this.state.isBranchDelete });
    };

    changeAccountName = (event: { target: { value: string; }; }) => {
        const regex = configJSON.regaxText
        const inputValue = event.target.value;
        if (regex.test(inputValue)) {
            this.setState({ accountNameValue: inputValue });
        }
    };

    changeAccountNumber = (event: { target: { value: string; }; }) => {
        const newValue = event.target.value.replace(/\D/g, '');
        this.setState({ accountNumberValue: newValue });
    };

    changeAccountBankName = (event: { target: { value: string; }; }) => {
        const regex = configJSON.regaxText
        const inputValue = event.target.value;
        if (regex.test(inputValue) || inputValue === "") {
            this.setState({ accountBankNameValue: inputValue });
        }
    };

    changeBankAdrs = (event: { target: { value: string; }; }) => {
        this.setState({ bankAdrsValue: event.target.value });
    };

    changeIban = (event: { target: { value: string; }; }) => {
        this.setState({ ibanValue: event.target.value });
    };

    changeShiftCode = (event: { target: { value: string; }; }) => {
        this.setState({ shiftValue: event.target.value });
    };

    onBankDetails = () => {
        let isCheck = true;
        const accountRegex = configJSON.accountNumberRegex;
        if (this.state.accountNameValue.length === 0) {
            isCheck = false;
            this.setState({ accountNameError: true, accountNameErrorTxt: configJSON.accountNameReq });
        } else {
            this.setState({ accountNameError: false, accountNameErrorTxt: "" });
        }

        if (this.state.accountNumberValue.length === 0) {
            isCheck = false;
            this.setState({ accountNumberError: true, accountNumberErrorTxt: configJSON.accountNumberReq });
        } else if (!accountRegex.test(this.state.accountNumberValue)) {
            isCheck = false;
            this.setState({ accountNumberError: true, accountNumberErrorTxt: configJSON.accountNumberLengthReq });
        } else {
            this.setState({ accountNumberError: false, accountNumberErrorTxt: "" });
        }

        if (this.state.accountBankNameValue.length === 0) {
            isCheck = false;
            this.setState({ accountBankNameError: true, accountBankNameErrorTxt: configJSON.bankNameReq });
        } else {
            this.setState({ accountBankNameError: false, accountBankNameErrorTxt: "" });
        }

        if (this.state.bankAdrsValue.length === 0) {
            isCheck = false;
            this.setState({ bankAdrsError: true, bankAdrsErrorTxt: configJSON.bankAdrsReq });
        } else {
            this.setState({ bankAdrsError: false, bankAdrsErrorTxt: "" });
        }

        if (this.state.ibanValue.length === 0) {
            isCheck = false;
            this.setState({ ibanError: true, ibanErrorTxt: configJSON.ibanReq });
        } else {
            this.setState({ ibanError: false, ibanErrorTxt: "" });
        }
        
        if (this.state.selectedCurrency === "none") {
            isCheck = false;
            this.setState({ currencyError: true, currencyErrorTxt: "Please select currency" });
        } else {
            this.setState({ currencyError: false, currencyErrorTxt: "" });
        }
        return isCheck;
    };

    // for manage branch
    openBranchModal = (id?:number) => {
        if(id){
            const filteredData = this.state.allBranchData.filter(item => item.attributes.id === id);
            this.setState((prevState) => ({branchModal: !prevState.branchModal, branchEditID: id, isBranchEdit: true, branchName:filteredData[0].attributes.branch_name,branchEmailID:filteredData[0].attributes.email,branchCountryCode:filteredData[0].attributes.country_code.toString(),branchMobileNumber:filteredData[0].attributes.phone_number.toString(),branchAgent:filteredData[0].attributes.managed_by, branchAgentError:"", branchEmailError:"", branchMobileNumberError:"", branchNameError:""}))
        }
        else {
            this.setState((prevState) => ({branchModal: !prevState.branchModal, branchEditID: 0, isBranchEdit: false, branchName:"",branchEmailID:"",branchCountryCode:"91-IN",branchMobileNumber:"",branchAgent:"", branchAgentError:"", branchEmailError:"", branchMobileNumberError:"", branchNameError:""}))
        }
    }

    changeBranchName = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        this.setState({ branchName: inputValue });
    };

    changeBranchEmail = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        const emailRegex = configJSON.emailRegex;
        if (inputValue === "") {
            this.setState({
                branchEmailError: "Email is required.",
                branchEmailID: inputValue
            });
        } else if (!emailRegex.test(inputValue)) {
            this.setState({
                branchEmailError: "Please enter a valid email address.",
                branchEmailID: inputValue
            });
        } else {
            this.setState({
                branchEmailError: "",
                branchEmailID: inputValue
            });
        }
    };

    changeBranchCountryCode = (event: SelectEventType) => {
        this.setState({ branchCountryCode: event.target.value as string, stateError: false });
    };

    changeBranchMobile = (event: { target: { value: string; }; }) => {
        this.setState({branchMobileNumberError:""})
        const inputValue = event.target.value.replace(/\D/g, "").trim();
        this.setState({ branchMobileNumber: inputValue });
    };

    getCountryCode = async () => {
        this.getCountryCodeAPICallID = await this.apiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getCountryCode
        });
      }
    
    setCountryCodes = (responseJson: any) => {
        this.setState({ countryCodes: responseJson })
        const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
        this.setState({ branchCountryCode: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}` })
    };

    fetchAllBranches = async () => {
        this.setState({ isLoader: true });
        this.fetchAllBranchAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.fetchAllBranch
        });
        this.setState({ isLoader: false });
    };

    fetchAllBranchSuccessCall = (responseJson: any) => {
        this.setState({allBranchData:responseJson.data})
    };

    handleSubmitBranch = async (event: any) => {
        event.preventDefault();

        const { branchName, branchEmailID, branchMobileNumber, branchAgent, branchCountryCode } = this.state;

        let isValid = true;

        if (!branchName.trim()) {
            this.setState({ branchNameError: "Branch name is required" });
            isValid = false;
        } else {
            this.setState({ branchNameError: "" });
        }

        const emailRegex = configJSON.emailRegex;
        if (!emailRegex.test(branchEmailID)) {
            this.setState({ branchEmailError: "Invalid email format" });
            isValid = false;
        } else {
            this.setState({ branchEmailError: "" });
        }

        const mobileRegex = configJSON.mobileReagx;
        const branchMobileNumberStr = branchMobileNumber.toString();

        if (!mobileRegex.test(branchMobileNumberStr)) {
            this.setState({ branchMobileNumberError: "Please enter valid mobile." });
            isValid = false;
        } else {
            this.setState({ branchMobileNumberError: "" });
        }
        if (!branchAgent.trim()) {
            this.setState({ branchAgentError: "Please select a member" });
            isValid = false;
        } else {
            this.setState({ branchAgentError: "" });
        }

        if (isValid) {

            const collectBranchData = {
                "data": {
                    "attributes": {
                        "branch_name": branchName,
                        "email": branchEmailID,
                        "country_code": branchCountryCode,
                        "phone_number": branchMobileNumber,
                        "managed_by": branchAgent
                    }
                }
            }
            this.setState({ isLoader: true });
            this.saveBranchAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.changePswrdApimethod,
                endPoint: configJSON.saveBranch,
                body: collectBranchData
            });
            this.setState({ isLoader: false });
        }
    };

    handleChangeBranchAgent = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        this.setState({ branchAgent: inputValue });
    };

    resetBranchFormData = () => {
        this.setState({
            branchModal:false,
            branchAgent:"",
            branchEmailID:"",
            branchCountryCode:"91-IN",
            branchName:"",
            branchMobileNumber:"",
            branchAgentError:"",
            branchEmailError:"",
            branchMobileNumberError:"",
            branchNameError:"",
            isBranchEdit:false,
            branchEditID:0,
            isLoader:false,
            isBranchDelete: false
        })

        this.fetchAllBranches()
    }

    handleSubmitBranchEdit = async (event: any) => {
        event.preventDefault();

        const { branchName, branchEmailID, branchMobileNumber, branchAgent, branchCountryCode } = this.state;

        let isValid = true;

        const mobileRegex = configJSON.mobileReagx;
        const branchMobileNumberStr = branchMobileNumber.toString();

        if (!mobileRegex.test(branchMobileNumberStr)) {
            this.setState({ branchMobileNumberError: "Please enter valid mobile." });
            isValid = false;
        } else {
            this.setState({ branchMobileNumberError: "" });
        }

        if (!branchAgent.trim()) {
            this.setState({ branchAgentError: "Please select a member" });
            isValid = false;
        } else {
            this.setState({ branchAgentError: "" });
        }
        
        const emailRegex = configJSON.emailRegex
        if (!emailRegex.test(branchEmailID)) {
            this.setState({ branchEmailError: "Invalid email format" });
            isValid = false;
        } else {
            this.setState({ branchEmailError: "" });
        }
        
        if (!branchName.trim()) {
            this.setState({ branchNameError: "Branch name is required" });
            isValid = false;
        } else {
            this.setState({ branchNameError: "" });
        }

        if (isValid) {

            const collectBranchData = {
                "data": {
                    "attributes": {
                        "managed_by": branchAgent,
                        "phone_number": branchMobileNumber,
                        "branch_name": branchName,
                        "country_code": branchCountryCode,
                        "email": branchEmailID,
                    }
                }
            }
            this.setState({ isLoader: true });
            this.saveBranchAPICallID = await this.apiCall({
                endPoint: `${configJSON.saveBranch}/${this.state.branchEditID}`,
                contentType: configJSON.changePswrdContentType,
                method: configJSON.httpPutMethod,
                body: collectBranchData
            });
            this.setState({ isLoader: false });
        }
    };

    handleDeleteBranch = async () => {
        this.setState({ isLoader: true });
        this.saveBranchAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.deleteMethodType,
            endPoint: `${configJSON.saveBranch}/${this.state.branchEditID}`,
        })
        this.setState({ isLoader: false });
    }

    validateMobileNo = () => {
        if (this.state.branchMobileNumber && this.state.branchCountryCode) {
            this.validateMobile(this.state.branchMobileNumber, this.state.branchCountryCode);
        }
    }

    validateMobile = async (mobileno: string, countrycode: string) => {
        this.validateMobileNoAPICallID = await this.apiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.validateMobileNumber}?mobile=${mobileno}&country_code=${countrycode}`
        });
    }

    setContactErrors = (responseJson: any) => {
        const errorMessage = responseJson.errors?.[0]?.message
        this.setState({branchMobileNumberError:errorMessage})
    }
    


        // bank information

        fetchAllBankDetails = async () => {
            this.setState({ isLoader: true });
            this.fetchAllBanksAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.staffApimethod,
                endPoint: configJSON.fetchAllBankDetails
            });
        };
    
        fetchAllBankSuccessCall = (responseJson: any) => {
            this.setState({allBankDetails:responseJson.data, isLoader:false})
        };
    
        modelOpen = (id?: number) => {
            if(id){
                const filteredData = this.state.allBankDetails.filter(detail => Number(detail.id) === id);
                this.setState((prevState) => ({
                    isModal: !prevState.isModal,
                    accountNameValue: filteredData[0].attributes.bankholder_name,
                    accountNumberValue: filteredData[0].attributes.account_number,
                    accountBankNameValue: filteredData[0].attributes.bank_name,
                    bankAdrsValue: filteredData[0].attributes.bank_address,
                    ibanValue: filteredData[0].attributes.iban,
                    shiftValue: filteredData[0].attributes.swiftcode,
                    bankEditID: id,
                    isBankEdit: true,
                    selectedCurrency:filteredData[0].attributes.currency_preference,
                }))
            }
            else {
                this.setState({
                    isModal: !this.state.isModal,
                    accountNameValue: "",
                    accountNumberValue: "",
                    accountBankNameValue: "",
                    bankAdrsValue: "",
                    ibanValue: "",
                    shiftValue: "",
                    bankEditID: 0,
                    bankEditName:"",
                isBankEdit: false,
                selectedCurrency:"none",
                isLoader:false
                })
            }
        }
    
        updateBankInfo = async () => {
            const isValid = this.onBankDetails();
    
            if (isValid) {
                const body = {
                    bankholder_name: this.state.accountNameValue,
                    account_number: this.state.accountNumberValue,
                    bank_name: this.state.accountBankNameValue,
                    bank_address: this.state.bankAdrsValue,
                    iban: this.state.ibanValue,
                    swiftcode: this.state.shiftValue,
                    primary: true,
                    currency_preference: this.state.selectedCurrency
                }
        
                this.setState({ isLoader: true });
                this.saveBankInfoAPICallID = await this.apiCall({
                    contentType: configJSON.changePswrdContentType,
                    method: configJSON.httpPutMethod,
                    endPoint: `${configJSON.updateBankDetail}/${this.state.bankEditID}`,
                    body: body
                });
            }
        }
    
        setBankResponseError = (responseJson: any) => {
            this.setState({bankErrorResponse:responseJson.errors})
        };
    
        resetBankFormData = () => {
            this.setState({
                isModal: false,
                accountNameValue: "",
                accountNumberValue: "",
                accountBankNameValue: "",
                bankAdrsValue: "",
                ibanValue: "",
                shiftValue: "",
                bankEditID: 0,
                bankEditName:"",
                isBankEdit: false,
                bankErrorResponse:[],
                isLoader: false,
                accountNameError:false, 
                accountNumberError:false, 
                accountBankNameError:false, 
                bankAdrsError:false, 
                ibanError:false, 
                shiftError:false,
                selectedCurrency:"none",
                currencyError:false,
                isDelete:false
            })
    
            this.fetchAllBankDetails()
        }
    
        saveBankInfo = async () => {
            const isValid = this.onBankDetails();
            if (isValid) {
                const body = {
                    account_number: this.state.accountNumberValue,
                    bank_name: this.state.accountBankNameValue,
                    primary: this.state.allBankDetails.length === 0 ? true : false,
                    swiftcode: this.state.shiftValue,
                    bank_address: this.state.bankAdrsValue,
                    currency_preference: this.state.selectedCurrency,
                    iban: this.state.ibanValue,
                    bankholder_name: this.state.accountNameValue
                }
    
                this.setState({ isLoader: true });
                this.saveBankInfoAPICallID = await this.apiCall({
                    contentType: configJSON.changePswrdContentType,
                    method: configJSON.httpPostMethod,
                    endPoint: configJSON.updateBankDetail,
                    body: body
                });
            }
        }
    
        updateBankPrimary = async (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
            const body = {
                primary: event.target.checked,
            }
    
            this.setState({ isLoader: true });
            this.saveBankInfoAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.httpPutMethod,
                endPoint: `${configJSON.updateBankDetail}/${id}`,
                body: body
            });
        }
    
        handleChangeCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
            const selectedValue = event.target.value as string;
            this.setState({ selectedCurrency: selectedValue });
        };
    
        handleDeleteID = (id:number, bankName: string) => {
            this.setState({ isDelete: true, bankEditName: bankName,bankEditID:id });
        }

        handleDeleteBranchID = (id:number, bankName: string) => {
            this.setState({ isBranchDelete: true, branchName: bankName,branchEditID:id });
        }
    
        deleteBankInfo = async () => {
            this.setState({ isLoader: true });
            this.saveBankInfoAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.deleteMethodType,
                endPoint: `${configJSON.updateBankDetail}/${this.state.bankEditID}`,
            });
        }

        setBankMessages = (responseJson: any) => {
        this.resetBankFormData()
        this.setState({isAlert: true, alertMsg:responseJson.meta.message, alertType:"success"})
    }
    
        // bank information end
    /* istanbul ignore next */
    fetchAllDocuments = async () => {
        this.setState({ isLoader: true });
        this.fetchAllDocumentsID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.getAllDocuments
        });
    };
    fetchAllDocumentsSuccessCall = (responseJson: any) => {
        /* istanbul ignore next */
        this.setState({allDocumentsData:responseJson.data})
    };
    // fetch documents end

     /* istanbul ignore next */
     handleFileChangeDoc = async (
        event: React.ChangeEvent<HTMLInputElement>,
        documentCategory: string
    ) => {
        const file = this.getFileFromEvent(event);
        if (!file) return;
    
        const fileExtension = this.getFileExtension(file);
        if (!this.isAcceptedFormat(fileExtension)) {
            alert('Please upload a file in .png, .jpg, .pdf, or .doc format.');
            return;
        }
    
        this.setState({ selectedFileNew: file, isLoader: true }, async () => {
            if (this.state.selectedFileNew) {
                try {
                    const formData = this.createFormData(this.state.selectedFileNew);
                    const apiEndpoint = this.getApiEndpoint(documentCategory);
                    if (apiEndpoint) {
                        this.uploadDocumentID = await this.uploadDocument(apiEndpoint, formData);
                        await this.fetchAllDocuments();
                    }
                } catch (error) {
                    console.error("Error uploading document:", error);
                } finally {
                    this.setState({ isLoader: false });
                }
            }
        });
    };
    
      /* istanbul ignore next */
    getFileFromEvent = (event: React.ChangeEvent<HTMLInputElement>): File | null => {
        return event.target.files ? event.target.files[0] : null;
    };
    
      /* istanbul ignore next */
    getFileExtension = (file: File): string | undefined => {
        return file.name.split('.').pop()?.toLowerCase();
    };

      /* istanbul ignore next */
    isAcceptedFormat = (fileExtension?: string): boolean => {
        const acceptedFormats = ['png', 'jpg', 'pdf', 'doc'];
        return fileExtension ? acceptedFormats.includes(fileExtension) : false;
    };
      /* istanbul ignore next */
    createFormData = (file: File): FormData => {
        const formData = new FormData();
        formData.set("doc", file);
        return formData;
    };
      /* istanbul ignore next */
    getApiEndpoint = (documentCategory: string): string => {
        switch (documentCategory) {
            case 'agent_docs':
                return configJSON.uploadlDocuments;
            case 'personal_id':
                return configJSON.uploadPersonalId;
            case 'business_registration_certificate':
                return configJSON.uploadBuisnessDocs;
            default:
                return '';
        }
    };
      /* istanbul ignore next */
    uploadDocument = async (apiEndpoint: string, formData: FormData): Promise<any> => {
        return this.apiCall({
            method: configJSON.changePswrdApimethod,
            endPoint: apiEndpoint,
            body: formData,
            type: "formData"
        });
    };

    /* istanbul ignore next */
    uploadDocumentSuccessCall = (responseJson: any) => {
        this.setState({ uploadedDocumentData: responseJson.data, isLoader: false });
        this.fetchAllDocuments();
        this.setState({ isLoader: false });
    };
    /* istanbul ignore next */

    handleDeleteDoc = async (
        event: React.MouseEvent<HTMLDivElement>, 
        id: string
      ) => {
        try {
            this.deleteDocumentID = await this.apiCall({
                method: configJSON.deleteMethodType,
                endPoint: `${configJSON.deleteDocuments}/${id}`,
            });
            await this.fetchAllDocuments();
        } catch (error) {
            console.error("Error deleting document:", error);
        } finally {
            this.setState({ isLoader: false });
        }
      }
      /* istanbul ignore next */
      deleteDocumentSuccessCall= () => {
        this.fetchAllDocuments();
        this.setState({ isLoader: false });
    };

    handleRowClick = (rowId: number) => {
        this.setState((prevState) => ({
            expandedRowId: prevState.expandedRowId === rowId ? null : rowId,
        }));
    };
    // end manage branch


    // personal info started

    updateNotificationPreference = async () => {
        const body = {
            "preferred_notification": this.state.notifications,
            "allow_all": this.state.allowAll
          }
        this.setState({ isLoader: true });
        this.saveNotificationPreference = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.updateNotificationPreference,
            body: body
        });
        this.setState({ isLoader: false });
    }

    saveProfileAccount = async () => {
        let userDetails = await getStorageData("userDetails")
        const body = {
            "data":{
                "attributes":{
                    "email": this.state.memberEmailTxtValue,
                    "user_name": this.state.memberTxtValue,
                    "marital_status": this.state.maritalValue,
                    "phone_number": this.state.mobileNumberValue,
                    "country": this.state.countryValue,
                    "state": this.state.stateValue,
                    "city": this.state.cityValue,
                    "date_of_birth": this.state.birthDateValue,
                    "gender": this.state.genderValue,
                    "marriage_anniversary": this.state.marriageDateValue
                }
            }
        }

        this.setState({ isLoader: true });
        this.saveProfileAccountAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.getProfileEndPoint + userDetails,
            body: body
        });
    };

    handleUpdateProfile = (responseData:any) => {
        this.setState({isLoader: false, isAlert: true, alertMsg:responseData.meta.message, alertType:"success"})
    }
    // personal info ended

    // Customizable Area End
}